/* eslint-disable buk/no-static-import */
/** @file
 * Archivo encargado de la lógica para desplegar el organigrama de empleados.
 * Uso en el organigrama general de la empresa y organigrama por cada división.
 */
import onmount from 'onmount';
import { customShowChildren, customHideChildren } from './orgchart-node-handlers.js';

const loadDependencies = async function loadDependencies(url) {
  const { getOrgChartData, loadOrgChart } = await import('./orgchart-v2.js');
  const html2canvasModule = await import('html2canvas');
  window.html2canvas = html2canvasModule.default;
  await loadOrgChart();
  const data = await getOrgChartData(url);
  return data;
};

const checkOrgchartData = function checkOrgchartData(data) {
  if (Object.keys(data).length === 0) {
    $('#orgchart-employee-v2-info').removeClass('d-none');
    $('#orgchart-employee-v2-loader').addClass('d-none');
    return undefined;
  }
  return data;
};

const findNodeById = function findNodeById(chart, providedId) {
  // Retorna el nodo como objeto de jQuery. Esto es necesario para manipularlo con addClass y removeClass.
  if (typeof providedId === 'string') {
    providedId = parseInt(providedId, 10);
  }
  const orgchart = chart['$chart'];
  var foundNode = orgchart.find('.node').filter(function (_index, node) {
    return $(node).data('nodeData').id === providedId;
  });
  return foundNode;
};

function highlightNode(node) {
  $(node)
    .addClass('focused');
}

const getNodeAncestry = function getNodeAncestry(chart, node) {
  // Retorna Array donde el primer elemento es el nodo padre, el siguiente el padre del padre, sucesivamente hasta el nodo raiz.
  var foundAncestors = [];
  if(node.data('parent')) {
    var parent = findNodeById(chart, node.data('parent'));
    if (parent.length !== 1) {
      return [];
    }
    foundAncestors.push(parent);
    return foundAncestors.concat(getNodeAncestry(chart, parent));
  }
  return foundAncestors;
};

// eslint-disable-next-line no-unused-vars
const hasChildNodes = function hasChildNodes($node, $chart) {
  // Retorna un bool indicando si el nodo tiene nodos hijos
  return $chart.getNodeState($node, 'children').exist;
};

const hasVisibleChildNodes = function hasVisibleChildNodes($node, $chart) {
  // Retorna un bool indicando si el nodo tiene nodos hijos visibles
  return $chart.getNodeState($node, 'children').visible;
};

const openChildNodes = function openChildNodes($node, $chart) {
  // Abre nodos hijos, si estaban cerrados.
  if ($node === undefined || $node === null) return;
  if (hasVisibleChildNodes($node, $chart)) return;
  const firstOpenedLevel = Number($node[0].getAttribute('data-level')) + 1;
  customShowChildren($node, $chart, firstOpenedLevel);
  $chart.triggerShowEvent($node, 'children');
};

const closeChildNodes = function closeChildNodes($node, $chart) {
  // Cierra nodos hijos, si estaban abiertos.
  if ($node === undefined || $node === null) return;
  if (!hasVisibleChildNodes($node, $chart)) return;
  customHideChildren($node, $chart);
  $chart.triggerHideEvent($node, 'children');
};

const toggleChildNodes =  function toggleChildNodes($node, $chart) {
  // Cambia nodos hijos de visibles a escondidos y viceversa
  if (hasVisibleChildNodes($node, $chart)) {
    closeChildNodes($node, $chart);
  }
  else {
    openChildNodes($node, $chart);
    if ($('#orgchart-employee-v2').hasClass('searchable')) {
      $node[0].scrollIntoView({ inline: 'center', block: 'nearest' });
    }
  }
};

const createSource = async function createSource() {
  const { default: Bloodhound } = await import(
    /* webpackChunkName: "typeahead" */ 'corejs-typeahead/dist/bloodhound.js');

  var normalize = function (input) {
    return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const queryTokenizer = function (q) {
    const normalized = normalize(q);
    return Bloodhound.tokenizers.whitespace(normalized);
  };

  const orgChartNodes = [];
  $('.node').each(function () {
    const fullName = getFullName($(this).data('nodeData'));
    const position = getJob($(this).data('nodeData'));
    let image = '/images/photo_error.jpg';
    if ($(this).data('nodeData').image) {
      image = $(this).data('nodeData').image;
    }
    const nodeData = {
      id: $(this).data('nodeData').id,
      fullName: fullName,
      normalizedName: normalize(fullName),
      normalizedPosition: normalize(position),
      position: position,
      picture: image,
    };
    orgChartNodes.push(nodeData);

  });

  return new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('normalizedName', 'normalizedPosition'),
    queryTokenizer: queryTokenizer,
    local: orgChartNodes,
  });
};

const dfsCollapse = function dfsCollapse(node, chart) {
  // Recorre el organigrama con DFS y cierra todos los nodos
  if (Object.hasOwn(node, 'children'))  {
    node.children.forEach((child) => {
      dfsCollapse(child, chart);
    });
    const nodeObject = findNodeById(chart, node.id);
    if (nodeObject.length === 1) {
      closeChildNodes(nodeObject, chart);
    }
  }
};

const collapseAllOrgchart = function collapseAllOrgchart(chart) {
  // Colapsa todos los nodos que tengan hijos
  const root = chart.getHierarchy();
  dfsCollapse(root, chart);
};

let org;
let ffEnabled;

onmount('#orgchart-employee-v2', async function () {
  let data;
  try {
    data = await loadDependencies($(this).data('url'));
    data = checkOrgchartData(data);
    if (data === undefined) return;
  }
  catch (error) {
    // Ignoramos los errores de networking ya que no tenemos mucho que hacer aquí
    // El status 0 indica este tipo de error
    if (error.status === 0) {
      $('#orgchart-employee-v2-fail').removeClass('d-none');
      $('#orgchart-employee-v2-loader').addClass('d-none');
      return;
    }
    throw error;
  }
  const exportFilename = $('#orgchart-employee-v2').attr('data-filename');
  ffEnabled = $('#orgchart-employee-v2').attr('data-ff-enabled') === 'true';
  org = $(this).orgchart({
    'exportButton': true,
    'exportFilename': exportFilename,
    'exportButtonName': '-',
    'data': data,
    'zoom': true,
    'zoomoutLimit': 0.25,
    'pan': true,
    'nodeContent': 'title',
    'visibleLevel': 1,
    nodeEnterLeaveHandler: null,
    'createNode': function ($node, nodeData) {
      $node.attr('data-level', nodeData.level);
      let contentToAppend = `
        <figure class="mb-1">
          ${buildTagImg(nodeData)}
          ${buildIconHonorario(nodeData)}
        </figure>
        <p class="c-name font-size-14 fw-600 mb-2">
          <span title="${getFullName(nodeData)}">${getFullName(nodeData)}</span>
        </p>
        <hr>
        <p class="c-job font-size-12 fw-900 text-basic-700 mb-1">
          <span title="${getProperty(nodeData, 'position_tooltip')}">${getJob(nodeData)}</span>
        </p>`;
      if (getProperty(nodeData, 'division')) {
        contentToAppend += `
          <p class="font-size-12 fw-500 text-basic-700 mb-0">
            <span title="${getProperty(nodeData, 'division_tooltip')}">${getProperty(nodeData, 'division')}</span>
          </p>
          <p class="font-size-12 fw-500 text-basic-700 mb-0">
            <span title="${getProperty(nodeData, 'department_tooltip')}">${getProperty(nodeData, 'department')}</span>
          </p>
        `;
      }
      $node.find('.content').append(contentToAppend);
      $node.children('.bottomEdge')
        .append(`<div class='node-children-text'>${nodeData.children.length}</div>`);
      $node.children('.edge').removeClass('oci-chevron-up');
      $node.children('.edge').addClass('oci-chevron-down');
    },
    'initCompleted': function (_$chart) {
      $('#orgchart-employee-v2-loader').addClass('d-none');
      var $container = $('#orgchart-employee-v2');
      $container.scrollLeft(($container[0].scrollWidth - $container.width()) / 2);
      $('#export-orgchart-png').on('click',
        async function () {
          $('#export-orgchart-png').prop('disabled', true);
          $('.bottomEdge').css('display', 'none');
          var $mask = $container.find('.mask');
          if($mask.length === 0) {
            $container.append(`
              <div class="mask d-flex justify-content-center align-items-center">
                <i class="buk-loader">
              </i></div>
            `);
          }
          else {
            $mask.removeClass('hidden');
          }
          await convertImagesToBase64(_$chart);
          $('.oc-export-btn').click();
          $('.oc-export-btn').promise().done(function () {
            $('.bottomEdge').css('display', 'flex');
            $('.bottomEdge').css('flex-direction', 'row');
            $('#export-orgchart-png').prop('disabled', false);
          });
        }
      );
      $('.node').unbind('mouseenter').unbind('mouseleave');
      _$chart.unbind('mousedown').unbind('touchstart');
      _$chart.unbind('mouseup').unbind('touchend');
      _$chart.on('mousedown touchstart', customPanStartHandler);
      _$chart.on('mouseup touchend', customPanEndHandler);

      const box = document.querySelector('.orgchart-header');
      box.addEventListener('gesturestart', function (e) {
        e.preventDefault();
        document.body.style.zoom = 0.99;
      });

      box.addEventListener('gesturechange', function (e) {
        e.preventDefault();
        document.body.style.zoom = 0.99;
      });

      box.addEventListener('gestureend', function (e) {
        e.preventDefault();
        document.body.style.zoom = 0.99;
      });

      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const orgchartWidth = entry.target.style.width || $('.orgchart').width();
          const containerWidth = $('#orgchart-employee-v2').width();
          if(containerWidth >  orgchartWidth) {
            $('.orgchart').css('transform', 'matrix(1, 0, 0, 1, 1, 1)');
          }
        }
      });
      resizeObserver.observe(document.querySelector('.orgchart'));
    },
  });
  $('.node').off('click', '.bottomEdge');
  $('.node').on('click', '.bottomEdge', customBottomEdgeClickHandler(org));
  org.$chartContainer.off('wheel');
  $(document).off('touchmove');
  org.$chartContainer.on('wheel', { 'oc': org },
    ffEnabled ? wheelEventHandler(org.$chart) : customZoomWheelHandler(org)
  );
  if (ffEnabled) {
    document.addEventListener('keydown', keyDownHandler);
  }
  $('#zoom-in').on('click', function () {
    if (ffEnabled) {
      const { x, y } = getOrgchartContainerPosition();
      customZoomingHandlerWithReference(org.$chart, x, y, 1.2);
      return;
    }

    var newScale  = 1.2;
    org.setChartScale(org.$chart, newScale);
  });
  $('#zoom-out').on('click', function () {
    if (ffEnabled) {
      const { x, y } = getOrgchartContainerPosition();
      customZoomingHandlerWithReference(org.$chart, x, y, 0.8);
      return;
    }

    var newScale  = 0.8;
    org.setChartScale(org.$chart, newScale);
  });
  $('#collapse').on('click', () => collapseAllOrgchart(org));
  $('#orgchart-employee-v2').addClass('searchable');
  $('#orgchart_employee_search').on('typeahead:select', (_ev, suggestion) => {
    _ev.preventDefault();
    $('#orgchart_employee_search').typeahead('val', '');
    $('.node').removeClass('focused');
    searchNode(findNodeById(org, suggestion.id), org);
    $('#orgchart_employee_search').trigger('amplitudeTracking', {
      message: 'Search_OrganizationChart', data: {  },
    });
  });
  $(document).on('touchmove', { 'oc': org }, customZoomingHandler(org));
});

const emptyTemplate = `
<div class="no-results text-center text-muted">{noResultText}</div>
`;

const searchingTemplate = `
<div class="tt-search text-center"><span class="buk-loader"></span></div>
`;

const elementTemplate = `
<div class="tt-suggestion tt-selectable clearfix">

  <img class="direct-chat-img" alt="{name}" height="55" width="55" src="{img}">
  <div class="search-result-text overflow-hidden">
    <div class="name text-truncate " data-toggle="tooltip" title="{name} ({position})">{name}</div>
    <div class="position text-truncate" data-toggle="tooltip">{position}</div>
  </div>
</div>
`;

function waitForOrgchart() {
  return new Promise(resolve => {
    const checkIfLoaded = setInterval(function () {
      if ($('.orgchart').length > 0 && $('.orgchart').is(':visible')) {
        clearInterval(checkIfLoaded);
        resolve();
      }
    }, 50);
  });
}

function openAncestryPath($chart, nodeAncestors) {
  for (var i = nodeAncestors.length - 1; i >= 0; i--) {
    var currentNode = nodeAncestors[i];
    openChildNodes(currentNode, $chart);
  }
}

function searchNode(targetNode, $chart) {
  if (targetNode.length !== 1) return;
  const nodeAncestors = getNodeAncestry($chart, targetNode);
  openAncestryPath($chart, nodeAncestors);
  highlightNode(targetNode);
  $('.orgchart').addClass('smooth-move');
  $('.orgchart').css('transform', 'matrix(1, 0, 0, 1, 1, 1)');
  setTimeout(function () {
    $('.orgchart').removeClass('smooth-move');
    $(targetNode)[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, 100);
  $(targetNode)[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
}

onmount('#orgchart_employee_search', async function () {
  await waitForOrgchart();
  $('.orgchart-employee-search').css('visibility', 'visible');
  $('#export-orgchart-png').css('visibility', 'visible');
  await import(/* webpackChunkName: "typeahead" */ 'corejs-typeahead/dist/typeahead.jquery');
  const $self = $(this);
  const noResultText = $('#orgchart-employee-v2').data('no-result-text');
  const dataSource = await createSource();
  dataSource.initialize();
  $self.typeahead(
    {
      hint: false,
      highlight: true,
    },
    {
      name: 'orgchart-employee-search',
      display: 'fullName',
      highlight: true,
      async: false,
      source: dataSource,
      templates: {
        pending: searchingTemplate,
        empty: emptyTemplate.replace('{noResultText}', noResultText),
        suggestion: e => elementTemplate.replace('{id}', e.id).replace(/{name}/g, e.fullName)
          .replace('{img}', e.picture).replace(/{position}/g, e.position),
      },
    }
  );

  $self.on('keydown', function (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  });
});

function buildTagImg(nodeData) {
  let src = '/images/photo_error.jpg';
  let res = '';
  let isImage = true;
  if (nodeData.image) {
    src = nodeData.image;
    if(nodeData.image.toLowerCase().endsWith('.svg')) {
      res = `<svg crossorigin="anonymous" style="background-image: url(${src}); background-size: contain"/></svg>`;
      isImage = false;
    }
  }
  if(isImage) {
    res = `
      <svg
        width="120"
        height="120"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1">
        <g>
          <pattern id="grump_avatar-${nodeData.id}" width="120" height="120" patternUnits="userSpaceOnUse">
            <image
              href="${src}"
              width="120"
              height="120"
              x="0"
              y="0">
            </image>
          </pattern>
          <rect x="0" width="120" height="120" rx="8" style="fill: url(#grump_avatar-${nodeData.id});" />
        </g>
      </svg>
    `;
  }
  return res;
}

function convertImagesToBase64(_$chart) {
  const promises = [];
  const $elements = [];
  _$chart.find('.node').filter((_index, element) => {
    if(customGetNodeState($(element)).visible === true) {
      if($(element).find('pattern image').length > 0) {
        $elements.push($(element).find('pattern image')[0]);
      }
    }
  });

  $($elements).map(function () {
    const promise = $.Deferred();
    const i = this;
    if($(i).attr('href').toLowerCase().startsWith('http') || $(i).attr('href') === '/images/photo_error.jpg') {
      var img = new Image();
      img.onload = function () {
        var newCanvas = document.createElement('canvas');
        newCanvas.width = img.width;
        newCanvas.height = img.height;
        var ctx = newCanvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        var dataURL = newCanvas.toDataURL('image/png');
        $(i).attr('href', dataURL);
        promise.resolve();
      };
      // Don't fail on error, just continue
      img.onerror = () => promise.resolve();
      img.crossOrigin = 'anonymous';
      img.src = $(i).attr('href') + '?123123';
      promises.push(promise);
    }
  });
  return Promise.all(promises);
}

function customGetNodeState($node) {
  const $target = $node;
  if ($target.length) {
    if (!(($target.closest('.nodes').length && $target.closest('.nodes').is('.hidden')) ||
      ($target.closest('.hierarchy').length && $target.closest('.hierarchy').is('.hidden')) ||
      ($target.closest('.vertical').length && ($target.closest('.nodes').is('.hidden') ||
      $target.closest('.vertical').is('.hidden')))
    )) {
      return { 'exist': true, 'visible': true };
    }
    return { 'exist': true, 'visible': false };
  }
}

function buildIconHonorario(nodeData) {
  if (nodeData.isHonorario)
    return `<span class="material-icons">access_time</span>`;
  return '';
}

function getFullName(nodeData) {
  let fullName = '';
  if (nodeData.name)
    fullName = nodeData.name + ' ' + nodeData.last_name;

  return fullName;
}

function getJob(nodeData) {
  let job = '';
  if (nodeData.position_first_line) {
    job = nodeData.position_first_line;
  }
  if (nodeData.position_second_line) {
    job += ' ' + nodeData.position_second_line;
  }
  return job;
}

function getProperty(nodeData, propName) {
  return nodeData[propName] || '';
}

function customPanStartHandler(e) {
  const $chart = $(e.delegateTarget);
  if(e.type === 'touchstart' && e.touches && e.touches.length > 1) {
    $chart.data('panning', false);
    return;
  }
  $chart.css('cursor', 'move').data('panning', true);
  let lastX = 0;
  let lastY = 0;
  const lastTf = $chart.css('transform');
  if (lastTf !== 'none') {
    const temp = lastTf.split(',');
    if (lastTf.indexOf('3d') === -1) {
      lastX = parseInt(temp[4]);
      lastY = parseInt(temp[5]);
    }
    else {
      lastX = parseInt(temp[12]);
      lastY = parseInt(temp[13]);
    }
  }
  let startX = 0;
  let startY = 0;
  if (!e.targetTouches) { // pand on desktop
    startX = e.pageX - lastX;
    startY = e.pageY - lastY;
  }
  else if (e.targetTouches.length === 1) { // pan on mobile device
    startX = e.targetTouches[0].pageX - lastX;
    startY = e.targetTouches[0].pageY - lastY;
  }
  else if (e.targetTouches.length > 1) {
    return;
  }
  $chart.on('mousemove touchmove', function (ev) {
    if (!$chart.data('panning')) {
      return;
    }
    let newX = 0;
    let newY = 0;
    if (!ev.targetTouches) { // pand on desktop
      newX = ev.pageX - startX;
      newY = ev.pageY - startY;
    }
    else if (ev.targetTouches.length === 1) { // pan on mobile device
      newX = ev.targetTouches[0].pageX - startX;
      newY = ev.targetTouches[0].pageY - startY;
    }
    else if (ev.targetTouches.length > 1) {
      return;
    }
    const lastTfAux = $chart.css('transform');
    if (lastTfAux === 'none') {
      if (lastTfAux.indexOf('3d') === -1) {
        $chart.css('transform', 'matrix(1, 0, 0, 1, ' + newX + ', ' + newY + ')');
      }
      else {
        $chart.css('transform', 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ' + newX + ', ' + newY + ', 0, 1)');
      }
    }
    else {
      const matrix = lastTfAux.split(',');
      if (lastTfAux.indexOf('3d') === -1) {
        matrix[4] = ' ' + newX;
        matrix[5] = ' ' + newY + ')';
      }
      else {
        matrix[12] = ' ' + newX;
        matrix[13] = ' ' + newY;
      }
      $chart.css('transform', matrix.join(','));
    }
  });
}

function customPanEndHandler(e) {
  const $chart = $(e.delegateTarget);
  if ($chart.data('panning')) {
    $chart.data('panning', false).css('cursor', 'move').off('mousemove');
  }
}

function customBottomEdgeClickHandler(_$chart) {
  // Se gatilla al hacer click en el eje de un nodo. Abre/cierra nodos hijos.
  return function (event) {
    event.stopPropagation();
    var $node = $(event.delegateTarget);
    var $children = $node.siblings('.nodes').children().children('.node');
    if ($children.is('.sliding')) { return; }
    toggleChildNodes($node, _$chart);
  };
}

// Calcula la nueva matriz de transformación del gráfico después de hacer zoom basado en la posición del mouse.
function customZoomWheelHandler(_$chart) {
  return function (e) {
    const factor = isTrackPad(e.originalEvent) ? 0.03 : 0.07;
    e.preventDefault();

    const newScale = 1 + (e.originalEvent.deltaY > 0 ? -1 * factor : factor);

    if (ffEnabled) {
      customZoomingHandlerWithReference(e.data.oc.$chart, e.pageX, e.pageY, newScale);
      return;
    }

    e.data.oc.setChartScale(e.data.oc.$chart, newScale);
  };
}

/**
 * Calcula la nueva matriz de transformación del gráfico después de hacer zoom basado en la posición de referencia.
 *
 * @param {*} e    Evento que desencadenó el zoom.
 * @param {*} refX Posición x de referencia relativa a la pantalla.
 * @param {*} refY Posición y de referencia relativa a la pantalla.
 * @param {number} newScale Nueva escala del gráfico.
 */
function customZoomingHandlerWithReference($chart, refX, refY, newScale) {
  // Obtiene la matriz de transformación actual
  let lastTf = $chart.css('transform');
  if (lastTf === 'none') {
    lastTf = 'matrix(1, 0, 0, 1, 0, 0)';
  }

  const matrix = lastTf.split(',');
  const currentScale = parseFloat(matrix[3]);

  // Evita hacer zoom más allá de los límites definidos
  if ((currentScale <= org.options.zoomoutLimit && newScale <= 1)
    || (currentScale >= org.options.zoominLimit && newScale >= 1)) {
    return;
  }

  // Obtiene la posición del organigrama relativa a la pantalla
  const canvasX = $chart[0].getBoundingClientRect().x + $chart[0].getBoundingClientRect().width / 2;
  const canvasY = $chart[0].getBoundingClientRect().y + $chart[0].getBoundingClientRect().height / 2;

  // Obtiene la posición del organigrama relativa al contenedor
  const px = parseFloat(matrix[4]);
  const py = parseFloat(matrix[5].split(')')[0]);

  // Obtiene la nueva posición del organigrama relativa al contenedor
  const newPx = (newScale - 1) * (canvasX - refX) + px;
  const newPy = (newScale - 1) * (canvasY - refY) + py;

  matrix[4] = (newPx).toString();
  matrix[5] = (newPy).toString() + ')';
  $chart.css('transform', matrix.join(',') + ' scale(' + newScale + ',' + newScale + ')');
}

function isTrackPad(e) {
  var isTrackpad = false;
  isTrackpad = e.wheelDeltaY ? e.wheelDeltaY === -3 * e.deltaY : e.deltaMode === 0;
  return isTrackpad;
}

function customZoomingHandler(_$chart) {
  return function (e) {
    var oc = e.data.oc;
    oc.$chart.data('pinching', true);
    if(oc.$chart.data('pinching')) {
      var dist = oc.getPinchDist(e);
      oc.$chart.data('pinchDistEnd', dist);
      var diff = oc.$chart.data('pinchDistEnd') - oc.$chart.data('pinchDistStart');
      if (diff > 0) {
        oc.setChartScale(oc.$chart, 1.07);
      }
      else if (diff < 0) {
        oc.setChartScale(oc.$chart, 0.93);
      }
      oc.$chart.data('pinchDistStart', oc.$chart.data('pinchDistEnd'));
      oc.$chart.data('pinching', false);
    }
  };
}

/**
 * Maneja los eventos de la rueda del mouse/trackpad para controlar el zoom y movimiento del organigrama.
 * Si se presiona la tecla Ctrl, activa el zoom. De lo contrario, permite el desplazamiento del organigrama.
 *
 * @param {Object} _$chart - Objeto jQuery que representa el organigrama
 * @returns {Function} - Función que maneja el evento de la rueda
 */
function wheelEventHandler(_$chart) {
  return function (e) {
    if (e.ctrlKey) {
      customZoomWheelHandler(_$chart)(e);
      return;
    }

    wheelHandlerForMovement(e);
  };
}

/**
 * Maneja el desplazamiento del organigrama usando la rueda del mouse/trackpad.
 * Permite el desplazamiento vertical y horizontal, considerando la escala actual del organigrama.
 * Cuando se presiona Shift, permite el desplazamiento horizontal.
 *
 * @param {Event} e - Evento de la rueda del mouse/trackpad
 */
function wheelHandlerForMovement(e) {
  e.preventDefault();
  const $chart = e.data.oc.$chart;

  const lastTf = $chart.css('transform');
  const matrix = lastTf.split(',');

  const scale = parseFloat(matrix[3]);
  const deltaY = -e.originalEvent.deltaY * scale;
  const deltaX = -e.originalEvent.deltaX * scale;

  // Para la nueva posición en X el trackpad en movimiento horizontal transmite el evento a través de la propiedad deltaX.
  matrix[4] = (parseFloat(matrix[4]) + (e.shiftKey ? deltaY :  0) + deltaX).toString();
  matrix[5] = (parseFloat(matrix[5]) + (e.shiftKey ? 0 : deltaY)).toString();
  $chart.css('transform', matrix.join(','));
}

function keyDownHandler(e) {
  // 187 = +, 189 = -
  if (![187, 189].includes(e.keyCode) || $('#orgchart_employee_search').is(':focus')) {
    return;
  }
  const { x, y } = getOrgchartContainerPosition();
  const newScale = e.keyCode === 187 ? 1.2 : 0.8;

  customZoomingHandlerWithReference(org.$chart, x, y, newScale);
}

/**
 * Retorna la posición central del contenedor del organigrama.
 *
 * @returns {Object} - Objeto que contiene las coordenadas x e y.
 */
function getOrgchartContainerPosition() {
  const rect = $('#orgchart-employee-v2')[0].getBoundingClientRect();
  const x = rect.x + rect.width / 2;
  const y = rect.y + rect.height / 2;
  return { x, y };
}

export { checkOrgchartData,
  openChildNodes, closeChildNodes, toggleChildNodes,
  findNodeById, getNodeAncestry,
  createSource
};
